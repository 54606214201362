import { CSS } from '../_app/cuchillo/utils/CSS';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import BezierEasing from 'bezier-easing'

class VSliderImage {
  static selector = "data-scroll-slider-image"
  static isNativeAllowed = true;
  static easing = BezierEasing(0.69, 0.27, 0.43, 0.56);
  static easingRot = BezierEasing(0, 0, 0.31, 0.77);
  item;
  axis;
  scale0;
  scale1;
  scale2;
  rotate0;
  rotate1;
  rotate2;
  offset;
  x;
  y;
  z;
  width;

  constructor(__item) {
    this.item = __item;
    this.axis = "x";

    this.scale1 = this.item.getAttribute("data-end")!== null? Number(this.item.getAttribute("data-end")) : 1;
    this.scale0 = this.item.getAttribute("data-start")!== null? Number(this.item.getAttribute("data-start")) : 1.4;
    this.scale2 = 1.1;
    this.rotate0 = this.item.getAttribute("data-rotate-end")!== null? Number(this.item.getAttribute("data-rotate-end")) : 0;
    this.rotate1 = this.item.getAttribute("data-rotate-start")!== null? Number(this.item.getAttribute("data-rotate-start")) : 4;
    this.width = this.item.width;

    const TRANSLATE = CSS.getTranslate(this.item);
    this.x = TRANSLATE.x;
    this.y = TRANSLATE.y;
    this.z = this.item.style.zIndex || 0;

    this.offset = this.item.offsetLeft;
  }

  loop(__position, __progress, __progressInside) {
    const POS = this.axis === "x"? __position.x : __position.y;
    const PROGRESS = Maths.normalize(0, this.width, POS);
    const ALPHA = Maths.normalize(0, -this.width, POS);
    

    if(POS > 0) {
      const scale = Maths.lerp(this.scale0, this.scale1, VSliderImage.easing(PROGRESS));
      const rotate = Maths.lerp(this.rotate1, this.rotate0, PROGRESS);
      this.item.style[CSS.transform] = CSS.translate3D(this.x, this.y, this.z) + " " + CSS.scale3D(scale, scale) + " " + CSS.rotate3D(0,0,1,rotate);
    } else {
      /*const scale = 1 + (1 - ALPHA)*.1;*/
      const rotate = (1 - ALPHA) * -1;
      
      const scale = Maths.lerp(this.scale1, this.scale2, VSliderImage.easing(PROGRESS - 1));
      

      this.item.style[CSS.transform] = CSS.translate3D(POS * -.9, this.y, this.z) + " " + CSS.scale3D(scale, scale) + " " + CSS.rotate3D(0,0,1,rotate);
      this.item.style.opacity = ALPHA;
    }
  }

  dispose(){};

  resize(__size) {
    this.width = this.item.offsetWidth;
  }
}

Scroll._registerInsider(VSliderImage);

