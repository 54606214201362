import gsap, { Power1 } from "gsap";
import { Basics, isDebug, isTouch } from '../_app/cuchillo/core/Basics';
import { GetBy } from "../_app/cuchillo/core/Element";

export default class Preloader {
    _container;
    _content;
    _progress;

    static init (__call) {
        this._container = GetBy.id('Preloader');
        this._content = GetBy.class('content', this._container)[0];
        this._progress = GetBy.class('__progress', this._container);

        if(isDebug) {
           this._container.style.display = "none";
        }

        this.show(__call);
    }

    static update (__progress) {
    }

    static show (__call) {
        gsap.killTweensOf(this._progress);
        gsap.to(this._content, {
            alpha: 1,
            ease: Power1.easeOut,
            duration: .2,
            onComplete: () => {
                if(__call) __call();
            }
        });
    }

    static hide (__call) {
        gsap.to(this._container, {
            duration: .2,
            opacity: 0,
            delay: 0,
            onComplete: () => {
            this._container.style.display = "none";
            if(__call) __call();
            }
        });
    }
}
