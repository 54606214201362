import { Interaction } from '../core/Interaction';
import { Maths } from '../utils/Maths';
import Cursor from './Cursor';
import Cursor__Item from './Cursor__Item';
import { gsap, Power2 } from 'gsap';

export default class Cursor__Image extends Cursor__Item{
  image = new Image();
  enabled = false;
  _width;
  _height;
  _ratio;
  _x = 0;
  _ease = .2;
  animPositions = {
    x: 0,
    y: 0
  }

  get y() { return this._y; }
  set y(__y) {
    this._yabs = __y - this._height *.5;
    this._y = this._yabs * window.devicePixelRatio;
  }

  constructor(__src, __size, __ctx) {
    super(__size, __ctx);
    this.image.onload = () => {
      this.enabled = true;
      this._ratio = this.image.height/this.image.width;
      this._height = this._width * this._ratio
    };
    this.image.src = __src;
    this._width = this.size;
  }

  calcHeight(__n) { return (__n - this._height *.5) * window.devicePixelRatio; }
  calcWidth(__n) { return (__n - this._sizeabs *.5) * window.devicePixelRatio; }

  show() {
    this.alpha = 0;
    
    this.animPositions.x = this.calcWidth(Interaction.positions.mouse.x);
    this.animPositions.y = this.calcHeight(Interaction.positions.mouse.y);
    
    gsap.to(this,{alpha:1, duration:.2, ease:Power2.easeIn});
  }

  draw() {
    if(this.enabled) {
      this._ctx.globalAlpha = this.alpha;

      //this._yabs 
      this.animPositions = {
        x: Maths.lerp(this.animPositions.x, this._x, this._ease),
        y: Maths.lerp(this.animPositions.y, this._y, this._ease),
      }
      //const x 

      this._ctx.drawImage(this.image, this.animPositions.x, this.animPositions.y, this._size, this._size * this._ratio);
      this._ctx.restore();
      //this._ctx.translate(0,0);
    }
  }
}
