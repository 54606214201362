import gsap, { Power2 } from 'gsap';
import { Basics } from '../core/Basics';

import { GetBy } from '../core/Element';
import { Scroll } from '../scroll/Scroll';

export default class _ScrollTop {
  static container = GetBy.id("Scroll-Top");

  static isShow = true;
  static isShowing = true;
  static isFixed = false;

  static yOffset;

  static options = {
    show: {
      duration: .3,
      delay: 0,
      ease: Power2.easeOut
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power2.easeOut
    }
  }

  static init () {
    this.resize();
  }

  static directShow () {
    this.isShow = true;
  }

  static show () {
    if (!this.isShow) {
      this.isShow = true;
      this.show__effect();
    }
  }

  static show__effect () {
    gsap.to(this.container, {
      opacity: 1,
      duration: this.options.show.duration,
      delay: this.options.show.delay,
      ease: this.options.show.duration.ease,
      onComplete: () => {
        this.isShowing = false;
      }
    });
  }

  static directHide () {
    this.isShow = false;
  }

  static hide () {
    if (this.isShow) {
      this.isShow = false;
      this.hide__effect();
    }
  }

  static hide__effect () {
    gsap.to(this.container,
      {
        opacity: 0,
        duration: this.options.hide.duration,
        delay: this.options.hide.delay,
        ease: this.options.hide.duration.ease,
        onComplete: () => {
          this.isShowing = false;
        }
      });
  }

  static resize () {
    this.yOffset = window.innerHeight * -.25;
  }

  static loop () {
    if (Scroll.y >= this.yOffset && !this.isFixed) {
        this.hide();
    } else {
        this.show();
    }
  }

  dispose() {}
}
