import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { isSafari, isTouch } from '../_app/cuchillo/core/Basics';
import Forms from '../_app/cuchillo/forms/FormValidator';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Sketch from '../_app/cuchillo/3D/Sketch';

export default class Default extends Page {
  constructor() {
    super();

    Videos.init();
    Acordions.init();
    Forms.init();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    isSafari
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isSafari && !isTouch, multiplicator: 1 });
    Scroll.setScrollbar(new Scrollbar());
    Keyboard.mountPage(this.id);
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);