import { GetBy } from '../_app/cuchillo/core/Element';
import Win from '../_app/cuchillo/windows/Window';
import { gsap, Power3 } from "gsap";

class WinStatistics extends Win {

  items;
  _hasBlockScroll = false;

  constructor(__container) {
    super(__container, "statistics");
    this.items = [...GetBy.class("win-statistics__item", this.container)];
    this.directHide();
  }

  
  show__effect(__d = 0) {
    for(let i = 0; i < this.items.length; i++) {
      gsap.to(this.items[i],{alpha:1, duration:.4, delay:__d + i * .1});
    }
    this.afterShow();
  }

  afterShow() {
    super.afterShow();
  }

  hide__effect(__d = 0) {
    for(let i = 0; i < this.items.length; i++) {
      if(i == this.items.length - 1) {
        gsap.to(this.items[i],{alpha:0, duration:.4, delay:__d + i * .1, onComplete:() => { this.afterHide(); }});
      } else {
        gsap.to(this.items[i],{alpha:0, duration:.4, delay:__d + i * .1});
      }
    }
  }

  afterHide() {
    super.afterHide();
  }

  directHide() {
    for(let i = 0; i < this.items.length; i++) {
      gsap.set(this.items[i],{alpha:0});
    }

    super.directHide();
  }

  resize() {
    super.resize();
  }
}

new WinStatistics(GetBy.id("Statistics"));

