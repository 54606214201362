import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import BasicController from '../3D/BasicController';

class ScrollItem__WebGLSketch extends VScroll_Item {
  controller;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.controller = new BasicController(GetBy.class('__target', __link)[0]);

    this._call = () => {
      this.loop();
    }

    this.onShow = () => {
      this.controller.start();
      gsap.ticker.add(this._call);
    };
    this.onHide = () => {
      this.controller.stop();
      gsap.ticker.remove(this._call);
    };
    this.onMove = () => { };
  }

  loop() {
    // this.controller.loop({ progress: this.progress, y: this.realY });
    this.controller.loop();
  }

  dispose() {
    this.controller.dispose();
    super.dispose();
  }

  resize(__w, __h) {
    this.controller.resize();
    super.resize(__w, __h);
  }
}

Scroll._registerClass('webgl-sketch', ScrollItem__WebGLSketch);