import { Power2, Power3, Power4, gsap } from "gsap";
import { GetBy } from '../_app/cuchillo/core/Element';
import Win from '../_app/cuchillo/windows/Window';
import Plyr from "plyr";
import { Basics } from "../_app/cuchillo/core/Basics";

class WinPlayer extends Win {

  videoData = {}
  domPlayer;

  constructor(__container) {
    super(__container, "playervideo");
  
    this.bg = GetBy.class("bg", this.container)[0];
    this.header = GetBy.selector("header", this.container)[0];
    this.playerHolder = GetBy.class("player-holder", this.container)[0];
    this.domPlayer = GetBy.id("video");
    this.domTitle = GetBy.class("title", this.container)[0];
    this.domAuthor = GetBy.class("author", this.container)[0];
    this.directHide();
  }

  show__effect(__d = 0) {
    
    const tempData = Basics.tempValue.split(",");
    this.videoData = {
      provider: tempData[0],
      id: tempData[1],
      title: tempData[2] || "",
      author: tempData[3] || "",
    }

    this.domTitle.innerHTML = this.videoData.title.split("_").join(" ");
    this.domAuthor.innerHTML = this.videoData.author.split("_").join(" ");

    gsap.set(this.container, {opacity: 1, force3D: true });
    gsap.to(this.bg, {opacity: 1, duration: 0.3, delay: __d, ease:Power2.easeIn, force3D: true, onComplete: () => {
      this.afterShow();
    }}); 
  }

  afterShow() {
    super.afterShow();
    this.showTitle();
    this.setupPlayer();
  }

  setupPlayer() {
  
    this.domPlayer.setAttribute("data-plyr-provider", this.videoData.provider);
    this.domPlayer.setAttribute("data-plyr-embed-id", this.videoData.id);
    
    this.player = new Plyr('#video', {controls: [
      'play-large', // The large play button in the center
      'progress', // The progress bar and scrubber for playback and buffering
      'fullscreen', // Toggle fullscreen
      'autoplay'
    ]});

    //le pasame al player el provider
    this.player.source = {
      type: 'video',
      sources: [
        {
          src: this.videoData.id,
          provider: this.videoData.provider,
        },
      ],
    };

    //Cuando el video este liasto empezara a reproducirse
    /*this.player.on('ready', event => {
      this.player.play();
    });*/

    //Cuando se muestren los controles mostramos title y author
    this.player.on('controlsshown', event => {
      this.showTitle();
    });
    //Cuando se ocultan los controles ocultamos title y author
    this.player.on('controlshidden', event => {
      this.hideTitle();
    });
      
    
    gsap.to(this.playerHolder, {opacity: 1, duration: 0.2, delay: .2, ease:Power2.easeIn, force3D: true});
  }

  showTitle() {
    console.log("showTitle")
    gsap.to(this.header, {opacity: 1, duration: 0.3, delay: 0, ease:Power2.easeIn, force3D: true });
  }

  hideTitle() {
    gsap.to(this.header, {opacity: 0, duration: 0.3, delay: 0, ease:Power2.easeIn, force3D: true });
  }



  hide__effect(__d = 0) {
    gsap.to(this.container, {
      opacity: 0,
      duration: 0.3,
      delay: __d,
      force3D: true,
      ease:Power2.easeOut,
      onComplete: () => {
        this.directHide();
        this.afterHide();
      }
    });
  }

  afterHide() {
    if(this.player) {
    this.domPlayer.removeAttribute("data-plyr-provider");
    this.domPlayer.removeAttribute("data-plyr-embed-id");
    //borramos el video y el player
    this.player.destroy();
    this.player = null;
    }
    
    super.afterHide();
  }

  directHide() {
    gsap.set(this.container, {opacity: 0, force3D: true });
    gsap.set(this.bg, {opacity: 0, scale:1.1, force3D: true });
    gsap.set(this.header, {opacity: 0, force3D: true });
    gsap.set(this.playerHolder, {opacity: 0, force3D: true });
  }

  resize() {
    super.resize();
  }
}

new WinPlayer(GetBy.id("WinPlayer"));