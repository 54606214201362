import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Power2, Expo, gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Slider, Slider_Item } from '../_app/cuchillo/components/Slider';
import { GetBy } from '../_app/cuchillo/core/Element';
import { SplitText } from 'gsap/SplitText';
import { Functions } from '../_app/cuchillo/utils/Functions';

class ScrollItem__Testimonios extends VScroll_Item {
    _call;
    _slider;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._slider = new Slider(__link, SlideTestimonio);
        this._slider.goto(0);
        
        this.onShow = () => {
            this._slider.next();
            this._slider.enabled = true;
        };

        this.onHide = () => {
            this._slider.enabled = false;
        };
    }

    dispose() {

    }
}

class SlideTestimonio extends Slider_Item {
    _item;
    _text;
    _name;
    _role;
    _image;
    timeline;

    constructor(__item, __index, __slider) {
        super(__item, __index, __slider);

        this._item = __item;
        this._text = GetBy.class('text', __item)[0];
        this._name = GetBy.class('name', __item)[0];
        this._role = GetBy.class('role', __item)[0];
        this._image = GetBy.selector('img', __item)[0];

        this.setup();
    }

    setup() {
        this.timeline = gsap.timeline();
        this.timeline.pause();
        
        // Configurar el texto utilizando SplitText
        const splitText = new SplitText(this._text, { type: 'words' });
        const words = Functions.arrayRandom(splitText.words);
                
        // Ocultar las palabras y los caracteres inicialmente
        gsap.set([words, this._name, this._role], { opacity: 0 });
        gsap.set([this._image], { opacity: 0, scale: 2 });
        
        
        // Configurar la imagen para que aparezca con una escala de 2
        this.timeline.to(this._image, { scale: 1, duration: 1.4, ease: Expo.easeOut});
        this.timeline.to(this._image, { opacity: 1, duration: 0.2, ease: Power2.easeIn}, .2);
        // Animar las palabras para que aparezcan una a una
        words.forEach((word, index) => {
            this.timeline.to(word, { opacity: 1, duration: .1}, (index * 0.02));
        });
        
        // Configurar el nombre y el cargo para que aparezcan después del texto
        this.timeline.to(this._name, { opacity: 1, duration: 0.5 }, .5);
        this.timeline.to(this._role, { opacity: 1, duration: 0.8 }, .6);        
    }

    show__effect() {
        gsap.set(this._item, { opacity: 1 });
        this.timeline.restart();
    }

    hide__effect() {
        gsap.to(this._item, { opacity: 0, duration: .2, ease: Power2.easeOut, onComplete: () => { this.afterHide(); }});
    }
}

Scroll._registerClass('testimonios', ScrollItem__Testimonios);
