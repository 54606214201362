import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import InstagramPostStory from '../components/InstagramPostStory';
import FormSender from '../_app/cuchillo/forms/FormSender';

class ScrollItem__Instagram extends VScroll_Item {
  _file;
  _select;
  _ig;
  _form;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._change = e => { this.onChange(e) };
    this._changeFit = e => { this.onChangeFit(e) };

    this._select = GetBy.class('__select', this.item)[0];
    this._file = GetBy.class('__file', this.item)[0];
    this._form = GetBy.class('__form', this.item)[0];

    this._ig = new InstagramPostStory(GetBy.class('__holder', __link)[0]);

    this.onShow = () => {
      this._file.addEventListener('change', e => this._change(e));
      this._select.addEventListener('change', e => this._changeFit(e));
    };
    this.onHide = () => {
      this._file.removeEventListener('change', this._change);
      this._select.removeEventListener('change', e => this._changeFit(e));
    };
    this.onMove = () => { };
  }

  onChange(e) {
    const img = new Image;
    img.src = URL.createObjectURL(e.target.files[0]);

    const tempIg = this._ig;
    const fit = this._select.value;
    const name = this._form.dataset.name;

    async function draw() {
      let image = '';

      await FormSender.sendFile(
        { name: e.target.name, value: e.target.files[0] },
        { formName: name }
      ).then(res => {
        image = res.url;
      })
        .catch(e => console.log(e));

      const data = { html: FormSender.formatHTML({ image }) };

      await FormSender.sendEmail(data).catch(e => console.log(e));

      tempIg.img = img;
      tempIg.drawImages(fit);
    };

    img.onload = draw;
  }

  onChangeFit(e) {
    this._ig.drawImages(e.target.value);
  }

  resize(__w, __h) {
    super.resize(__w, __h);

    this._ig.resize();
    this._ig.drawImages(this._select.value);
  }
}

Scroll._registerClass('widget-instagram', ScrollItem__Instagram);
