import { Pane } from 'tweakpane';

export default class DebugPane {
    pane;

    static init() {
        this.pane = new Pane({ title: 'Settings', expanded: true });
    }

    static dispose() {
        if (this.pane) {
            this.pane.dispose();
            this.pane = null;
        }
    }
}
