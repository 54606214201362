import { Power2, Power3, Power4, gsap } from "gsap";
import { GetBy } from '../_app/cuchillo/core/Element';
import Win from '../_app/cuchillo/windows/Window';

class WinRegistration extends Win {
  constructor(__container) {
    super(__container, "registration");
    this.wrapper = GetBy.class("win-registration__wrapper", this.container)[0];
    this.title = GetBy.class("title", this.wrapper)[0];
    this.text = GetBy.class("text", this.wrapper)[0];
    this.fieldsets = GetBy.tag("fieldset", this.wrapper);
    this.button = GetBy.class("__submit", this.wrapper)[0];
    this.directHide();
  }

  show__effect(__d = 0) {
    


    

    /*gsap.fromTo(
      this.wrapper,
      { y: distance * 2},
      { y: 0, duration: 0.8, delay: __d, ease:Power4.easeOut, onComplete: this.afterShow.bind(this) }
    );*/

    const distance = window.innerWidth * 0.1; // Distancia relativa al ancho de pantalla
    gsap.to(this.container, {opacity: 1, duration: 0.3, delay: __d, ease:Power2.easeIn, force3D: true });
    __d = __d + .3;
    gsap.to(this.wrapper, {opacity: 1, duration: .3, delay: __d, ease:Power2.easeIn, force3D: true });
    gsap.to(this.wrapper, {scale: 1, duration: .6, delay: __d, ease:Power4.easeOut, force3D: true });
    
    __d = __d + .3;
    gsap.to(
      [this.title, this.text, this.fieldsets, this.button],
      { opacity: 1, duration: 0.5, ease:Power2.easeOut, delay: __d }
    );
  }

  hide__effect(__d = 0) {
    const distance = window.innerWidth * 0.1; // Distancia relativa al ancho de pantalla

    gsap.to(this.container, {opacity: 0, duration: 0.3, delay: __d, ease:Power2.easeOut, force3D: true });

    gsap.to(this.wrapper, {
      opacity: 0,
      duration: 0.3,
      delay: __d + .2,
      force3D: true,
      ease:Power2.easeOut,
      onComplete: () => {
        this.directHide();
        this.afterHide();
      }
    });
  }

  directHide() {
    gsap.set(this.container, {opacity: 0, force3D: true });
    gsap.set(this.wrapper, {opacity: 0, scale:1.1, force3D: true });
    gsap.set(this.title, {opacity: 0, force3D: true });
    gsap.set(this.text, {opacity: 0, force3D: true });
    gsap.set(this.fieldsets, {opacity: 0, force3D: true });
    gsap.set(this.button, {opacity: 0, force3D: true });

    //gsap.set(this.wrapper, { y: window.innerWidth * 0.1, opacity: 0 }); // Distancia relativa al ancho de pantalla
  }

  resize() {
    super.resize();
  }
}

new WinRegistration(GetBy.id("RegistrationForm"));