import gsap, { Power1, Power2, Expo, Power4 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Ease } from '../_app/cuchillo/utils/Ease';

class ScrollItem__BillboardProject extends VScroll_Item {
  _bg;
  _title;
  _tl;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.setupAnimation();

   

    /*this._title = new SplitText(GetBy.class('__title', __link)[0], {
      type: 'lines, words, chars',
      linesClass: 'line-parent',
      wordsClass: 'line-child'
    }).words;
    gsap.set(this._title, { y: '200%' });*/

    this.onShow = () => {
      this._tl.play();
    };
    this.onHide = () => {};
    this.onMove = () => {}
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  showTitle() {

  }

  setupAnimation() {
    this._tl = this.TimelineSplitTextIntro(GetBy.selector("[data-title-animation]", this.item));
    this._tl.from(GetBy.selector("[data-label-animation]")[0], {alpha:0, y:"-72%", duration: 1, ease: Ease.EASE_CUCHILLO_IN_OUT, force3D: true}, .8);
    this._tl.from(GetBy.selector("[data-bg-animation]")[0], {alpha:0, y:"100%", duration: 3, ease: Ease.EASE_CUCHILLO_OUT, force3D: true}, .6);
  }

  TimelineSplitTextIntro(__items, __call, __tl, __delay = 0) {
    const _class = "__" + new Date().getTime();
    let tl = __tl ? __tl : new gsap.timeline();

    for (let i = 0; i < __items.length; i++) {
      __items[i].classList.add(_class);
      __items[i].setAttribute("data-splittext", i);
    }

    tl.pause();

    const split = new SplitText("." + _class, {
      type: 'lines, words',
      wordsClass: "_c_" + _class
    });

    let tick = 0;
    let idParent = split.words[0].parentNode.parentNode.dataset.splittext;

    for (let i = 0; i < split.words.length; i++, tick++) {
      if(idParent != split.words[i].parentNode.parentNode.dataset.splittext) {
        __delay = 0;
        tick = 0;
      }
      
      tl.from(split.words[i], {y: "180%", scaleY:1, duration: Math.min(1.4, (.8 + .2 * tick)), ease: Ease.EASE_CUCHILLO_IN_OUT, force3D: true, onComplete: () => {}}, __delay);
      idParent = split.words[i].parentNode.parentNode.dataset.splittext;
    }

    /*let items = Functions.arrayRandom(split.words);

    let direction = -1;
    for (let i = 0; i < items.length; i++) {
      direction *= -1;
      let isLast = i == items.length - 1;

      tl.from(items[i], {
        alpha: 0, duration: 0.1, ease: Power3.easeOut, force3D: true, onComplete: () => {
          if (isLast && __call) __call();
        }
      }, __delay);

      tl.from(items[i], { color: "#0084FD", duration: .2 + (.1 * i), ease: Quad.easeOut, force3D: true }, __delay + .1);
      __delay += .025;
    }*/

    return tl;
  }
}

Scroll._registerClass('billboard-project', ScrollItem__BillboardProject);
