import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isTouch } from '../_app/cuchillo/core/Basics';

class ScrollItem__EspaciosWow extends VScroll_Item {
    _call;
    _slider;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if(!isTouch) {
            this._slider = new SliderScroll(GetBy.class("__slider", __link)[0], {
                interaction: true,
                hasScrollbar: true,
                itemClass: ScrollItem__EspaciosWow__Item,
                domControls: GetBy.class("__controls", __link)[0],
            });

            this._call = () => this.loop();
            this.onShow = () => {
                gsap.ticker.add(this._call);
            };

            this.onHide = () => {
                console.log("HIDE")
                gsap.ticker.remove(this._call);
            };
        }
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) this._slider.resize();
    }

    loop() {
        if (this._slider) {
            this._slider.loop();
        }
    }

    dispose() {
        gsap.ticker.remove(this._call);
        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__EspaciosWow__Item extends VScroll_Item {
   
    info;
    hasHiddenEnabled = true;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.onMove = () => {
            const progress = Maths.precission(this.progressInside,2);
            const norm = Maths.normalize(1,1.5,progress);
            const opacity = Maths.lerp(0,1,norm);
            this.item.style.opacity = opacity;
            
            
        }
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    //update () {
    /* if (this.progress > .5) {
       const p = Math.max(0, Maths.normalize(1, .5, this.progress));
       this._x = this._x + ((Metrics.WIDTH * .5) * p);
     }*/
    /* super.update();
  }

  mouseOver () { }
  mouseDown () { }
  mouseUp () { }

  show () {
    super.show();
  }

  hide () {
    super.hide();
  }*/
}

Scroll._registerClass('SliderEspaciosWow', ScrollItem__EspaciosWow);
