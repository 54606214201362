import { MeshBasicMaterial, BoxGeometry, Vector3 } from 'three';

import Sketch from '../_app/cuchillo/3D/Sketch';
import WebGLObject from '../_app/cuchillo/3D/WebGLObject';
import { isDebug } from '../_app/cuchillo/core/Basics';
import DebugPane from './DebugPane';

export const SETTINGS = {
    rotation: 0
}

export default class BasicController {
    cube;
    rot;

    constructor(target) {
        const geometry = new BoxGeometry();
        const material = new MeshBasicMaterial({ color: 0x00ff00 });

        const s = target.offsetWidth;
        const size = new Vector3(s, s, s);
        this.cube = new WebGLObject({
            geometry,
            material,
            size,
            target
        });
        this.cube.init();
        this.cube.active = true;

        Sketch.addItem(this.cube);
    }

    start() {
        if (isDebug) DebugPane.init();
    }

    stop() {
        if (isDebug) DebugPane.dispose();
    }

    loop() {
        this.rot += .008;
        this.cube.rot.y = this.rot + SETTINGS.rotation;

        this.cube.update();
    }

    resize() {
        this.cube.resize();
    }

    dispose() {
        Sketch.removeItem(this.cube);
        this.cube.dispose();
    }
}
