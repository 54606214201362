import { CSS } from '../../utils/CSS';
import { Scroll } from '../Scroll';
import { Maths } from '../../utils/Maths';

class VCounter {
  static selector = "data-scroll-counter"
  static isNativeAllowed = true;

  item;
  value0;
  value1;
  
  constructor(_item) {
    this.item = _item;



    this.value1 = this.item.getAttribute("data-end")!== null? Number(this.item.getAttribute("data-end")) : 100;
    this.value0 = this.item.getAttribute("data-start")!== null? Number(this.item.getAttribute("data-start")) : 0;
  }

  loop(__position, __progress) {
    
    const progress = Math.min(1, __progress + .5);
    
    let value = Math.min(this.value1, Math.round(Maths.lerp(this.value0, this.value1, progress)));
    this.item.textContent = value;
  }

  dispose(){};

  resize(__size) {}
}

Scroll._registerInsider(VCounter);

