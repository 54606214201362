import _DebugPane from '../_app/cuchillo/3D/DebugUtils/DebugPane';
import { SETTINGS } from './BasicController';

export default class DebugPane extends _DebugPane {
    static init() {
        super.init();

        this.pane.addInput(SETTINGS, 'rotation', {
            label: 'Rot',
            step: .01,
            min: 0,
            max: 10,
        });
    }
}
