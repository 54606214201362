import { Vector3 } from 'three';

import { isDebug } from '../core/Basics';
import WebGLSketch from './WebGLSketch';

export default class Sketch {
    _scene;
    static seed = 0.5;

    static init() {
        this._scene = new WebGLSketch({
            container: 'scene-three',
            debug: isDebug,
            transparent: true,
            cameraPos: new Vector3(0, 0, 1500),
            clearColorAlpha: 0
        });
    }

    static addItem(item) {
        this._scene.scene.add(item)
    }

    static removeItem(item) {
        this._scene.scene.remove(item);
    }

    static start() {
        this._scene.start();
    }

    static stop() {
        this._scene.pause();
    }

    static loop() {
        // console.log('Looping...', this._scene);
        this._scene.loop();
    }

    static resize() {
        this._scene.resize();
    }

    static getTime() {
        return this._scene.time;
    }

    static newSeed() {
        this.seed += 0.15;
    }
}
